// third-party components, hooks, and libraries/ packages
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// components
import { Button, FormContainer, InputField, Intro } from "../components/form_elements";
import { LAYOUT_AUTH } from "../components/layouts";

// styles
import "../styles/page_signup.css"

export default function PageSignup () {
    // state holders
    const form_data = useState();
    const [slideshowIndex, setSlideshowIndex] = useState(0);

    // submit form
    const handle_form_submit = () => {
        console.log("form_data", form_data[0]);
        return window.location = `/dashboard`
    }

    useEffect(() => {
        var interval,
            slideshow = document.getElementById("slideshow"),
            numberOfSlides = slideshow.children.length;

        interval = setInterval(() => {
            slideshow.children[slideshowIndex].scrollIntoView();
        }, 5000)

        slideshowIndex === numberOfSlides - 1 ? setSlideshowIndex(0) : setSlideshowIndex(slideshowIndex + 1);
        return clearInterval(interval)
    }, [slideshowIndex])

    return (
        <LAYOUT_AUTH pageName={ `signup` }>
            <main>
                <FormContainer context={ form_data }>
                    <Intro message={ "Vendors Portal" } />
                    <InputField label={ `Name` } type={ `text` } name={ `username` } id={ `username` } />
                    <InputField label={ `Password` } type={ `password` } name={ `password_take_1` } id={ `password_take_1` } />
                    <InputField label={ `Confirm Password` } type={ `password` } name={ `password_take_2` } id={ `password_take_2` } />
                    <Button callback={ handle_form_submit } label="Sign Up" />
                </FormContainer>
                <div className="alt">If you already have an account, sign in <Link to={{ pathname: "/" }}>here</Link></div>
            </main>
            <aside id="slideshow">
                <div className="slide style_a">Ready to join the Wear Ghana Festival 2024 as a vendor?</div>
                <div className="slide style_b">Sell and earn</div>
                <div className="slide style_a">Advertise your products</div>
                <div className="slide style_c">Track your sales and product inventory</div>
            </aside>
        </LAYOUT_AUTH>
    )
}