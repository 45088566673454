// third-party components
import { useState } from "react";
import { Link } from "react-router-dom";

// components
import { LAYOUT_AUTH } from "../components/layouts";
import { Button, FormContainer, InputField, Intro } from "../components/form_elements";

// images
import WGF24 from "../images/wear_ghana_festival_24_logo.svg"

// styles
import "../styles/page_login.css"

export default function PageLogin () {
    // state holder
    const form_data = useState({})

    // submit form
    const handle_form_submit = () => {
        console.log("form_data", form_data[0]);
        return window.location = `/dashboard`
    }

    return (
        <LAYOUT_AUTH pageName={ `login` }>
            <main>
                <div id="banner">
                    <div><img src={ WGF24 } alt="wear ghana festival 2024" /></div>
                </div>
                <div id="form">
                    <FormContainer context={ form_data }>
                        <Intro message={ "Vendors Portal" } />
                        <InputField label={ `Username` } type={ `text` } name={ `username` } id={ `username` } />
                        <InputField label={ `Password` } type={ `password` } name={ `password` } id={ `password` } />
                        <Button callback={ handle_form_submit } label="Sign In" />
                    </FormContainer>
                    <div className="alt">If you don't have an account, create one <Link to={{ pathname: "/signup" }}>here</Link></div>
                </div>
            </main>
        </LAYOUT_AUTH>
    )
}