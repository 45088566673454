// third-party components and hooks
import { useState } from "react";

// components
import { CARD_ITEM } from "../components/cards";
import { Button, Divider, FormContainer, InputField } from "../components/form_elements";
import { LAYOUT_DEFAULT } from "../components/layouts";

// images
import IMAGE_TEST from "../images/wear_ghana_festival_24_logo.svg"

// styles
import "../styles/page_add_item.css"

export default function PageAddItem () {
    // state holders
    const [ previewStyle, setPreviewStyle ] = useState(`grid`)
    const formData = useState({})

    // functions
    const handle_add_item_submit = () => {}

    return (
        <LAYOUT_DEFAULT pageName="add_item">
            <main>
                <FormContainer context={ formData }>
                    <div>Add Item</div>

                    <InputField label={ `Name` } name={ `name` } id={ `name` } type={ `text` } required={ true } />
                    <InputField label={ `Summary` } name={ `summary` } id={ `summary` } type={ `textarea` } required={ true } />

                    <InputField label={ `Description` } name={ `name` } id={ `name` } type={ `text` } required={ true } />
                    <InputField name={ `name` } id={ `name` } type={ `text` } required={ true } />
                    <InputField name={ `name` } id={ `name` } type={ `text` } required={ true } />
                    <div>New Line</div>
                    
                    <div>Color Variations</div>
                    <InputField label={ `Colors` } name={ `colors` } id={ `colors` } type={ `radio` } options={ [`red`, `orange`, `blue`] } required={ true } />
                    
                    <div>Size Options</div>
                    <InputField label={ `Sizes` } name={ `sizes` } id={ `sizes` } type={ `radio` } options={ [`small`, `small`, `small`] } required={ true } />
                    
                    <InputField label={ `Inventory` } name={ `inventory` } id={ `inventory` } type={ `number` } required={ true } />

                    <Divider />

                    <Button callback={ handle_add_item_submit } label={ `Submit` } />
                </FormContainer>
            </main>

            <aside>
                <div className="head">
                    <h3>Preview</h3>
                    <div className="view-buttons">
                        <div onClick={ () => setPreviewStyle(`list`) } className={ previewStyle === `list` ? `active` : null }>List View</div>
                        <div onClick={ () => setPreviewStyle(`grid`) } className={ previewStyle === `grid` ? `active` : null }>Grid View</div>
                    </div>
                </div>

                <div className={`body ${ previewStyle }`}><CARD_ITEM image={ IMAGE_TEST } name="Test" price="$Test" style={ previewStyle } /></div>
            </aside>
        </LAYOUT_DEFAULT>
    )
}