// components
import { LAYOUT_DEFAULT } from "../components/layouts";

// images
import WGF24 from "../images/wear_ghana_festival_24_logo.svg"

// styles
import "../styles/page_item_details.css";

export default function PageItemDetails () {
    return (
        <LAYOUT_DEFAULT pageName={ `item_details` }>
            <main>
                <div id="gallery">
                    <div id="preview"><img src={ WGF24 } alt="gallery" /></div>
                    <div id="images">
                        <div><img src={ WGF24 } alt="gallery" /></div>
                        <div><img src={ WGF24 } alt="gallery" /></div>
                        <div><img src={ WGF24 } alt="gallery" /></div>
                        <div><img src={ WGF24 } alt="gallery" /></div>
                        <div><img src={ WGF24 } alt="gallery" /></div>
                        <div><img src={ WGF24 } alt="gallery" /></div>
                    </div>
                </div>

                <div id="text">
                    <section id="brief">
                        <h3 id="name">Item name</h3>
                        <div id="price-n-inventory">
                            <div>Price: 1 Cedi</div>
                            <div>Inventory: 16 left</div>
                        </div>
                        <div id="summary">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsa enim placeat at maiores doloribus culpa voluptatem commodi sed velit ad molestiae laboriosam aliquam minus possimus, mollitia beatae. Harum, asperiores placeat.</div>
                    </section>

                    <section className="variety">
                        <div className="title">Colors</div>
                        <div className="options colors">
                            <div className="red"></div>
                            <div className="cream"></div>
                            <div className="white"></div>
                            <div className="black"></div>
                            <div className="blue"></div>
                            <div className="orange"></div>
                        </div>
                    </section>

                    <section className="variety">
                        <div className="title">Sizes</div>
                        <div className="options sizes">
                            <div>XXS</div>
                            <div>XS</div>
                            <div>S</div>
                            <div>M</div>
                            <div>L</div>
                            <div>XL</div>
                            <div>XXL</div>
                        </div>
                    </section>

                    <section id="description">
                        <div className="title">Description</div>
                        <div>Made of ___________</div>
                        <div>Made of ___________</div>
                        <div>Made of ___________</div>
                        <div>Made of ___________</div>
                        <div>Made of ___________</div>
                    </section>

                    <section id="ctas">
                        <div>Edit</div>
                        <div>Delete</div>
                    </section>
                </div>
            </main>
        </LAYOUT_DEFAULT>
    )
}