// third-party components and hooks
import { BrowserRouter, Route, Routes } from "react-router-dom";

// pages
import PageAddItem from "./pages/add_item";
import PageDashboard from './pages/dashboard';
import PageItems from './pages/items';
import PageItemDetails from './pages/item_details';
import PageLogin from "./pages/login";
import PagePayments from './pages/payments';
import PageProfile from './pages/profile';
import PageSessions from "./pages/sessions";
import PageSignup from "./pages/signup";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" Component={ PageLogin } />
        <Route path="/add_item" Component={ PageAddItem } />
        <Route path="/dashboard" Component={ PageDashboard } />
        <Route path="/items" Component={ PageItems } />
        <Route path="/item_details" Component={ PageItemDetails } />
        <Route path="/payments" Component={ PagePayments } />
        <Route path="/profile" Component={ PageProfile } />
        <Route path="/sessions" Component={ PageSessions } />
        <Route path="/signup" Component={ PageSignup } />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
