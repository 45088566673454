// components
import { CARD_SESSION } from "../components/cards";
import { LAYOUT_DEFAULT } from "../components/layouts";

// styles
import "../styles/page_sessions.css"

export default function PageSessions () {
    return (
        <LAYOUT_DEFAULT pageName={ `sessions` }>
            <main>
                <CARD_SESSION heading={ true } />
                <CARD_SESSION datetime={ `12/06/2021, 11:11` } location={ `Accra` } client={ `Windows, Chrome` } duration={ `24m` } />
                <CARD_SESSION datetime={ `12/06/2021, 11:11` } location={ `Accra` } client={ `Windows, Chrome` } duration={ `24m` } />
                <CARD_SESSION datetime={ `12/06/2021, 11:11` } location={ `Accra` } client={ `Windows, Chrome` } duration={ `24m` } />
                <CARD_SESSION datetime={ `12/06/2021, 11:11` } location={ `Accra` } client={ `Windows, Chrome` } duration={ `24m` } />
                <CARD_SESSION datetime={ `12/06/2021, 11:11` } location={ `Accra` } client={ `Windows, Chrome` } duration={ `24m` } />
                <CARD_SESSION datetime={ `12/06/2021, 11:11` } location={ `Accra` } client={ `Windows, Chrome` } duration={ `24m` } />
            </main>
        </LAYOUT_DEFAULT>
    )
}