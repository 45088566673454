// styles
import "../styles/cards.css"

export function CARD_ITEM (props) {
    return (
        <div className={ `card item ${ props?.style }` }>
            <div className="image-container"><img src={ props?.image } alt="item" /></div>
            <div className="text-container">
                <div className="name">{ props?.name }</div>
                <div className="price-n-inventory">
                    <div>{ props?.price }</div>
                    <div>{ props?.inventory }</div>
                </div>
            </div>
        </div>
    )
}

export function CARD_SESSION (props) {
    return (
        <div className={ `card session ${ props?.heading ? `heading` : null }` }>
            <div className="datetime">{ props?.heading ? `DateTime` : props?.datetime }</div>
            <div className="location">{ props?.heading ? `Location` : props?.location }</div>
            <div className="client">{ props?.heading ? `Client` : props?.client }</div>
            <div className="duration">{ props?.heading ? `Duration` : props?.duration }</div>
        </div>
    )
}