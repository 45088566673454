const ITEMS = [
    {
        name: "Beret",
        price: "85",
        inventory: "16",
        category: "hat",
        status: "published"
    },
    {
        name: "Round-Neck T-Shirt",
        price: "25",
        inventory: "12",
        category: "top",
        status: "pending"
    },
    {
        name: "V-Neck T-Shirt",
        price: "28",
        inventory: "12",
        category: "top",
        status: "pending"
    },
    {
        name: "Pearl Necklace",
        price: "850",
        inventory: "3",
        category: "necklace",
        status: "published"
    },
    {
        name: "Silver Cuban Chain",
        price: "750",
        inventory: "1",
        category: "necklace",
        status: "published"
    },
    {
        name: "Swimming Shorts",
        price: "86",
        inventory: "9",
        category: "bottom",
        status: "published"
    },
    {
        name: "Bermuda Shorts",
        price: "135",
        inventory: "16",
        category: "bottom",
        status: "published"
    },
    {
        name: "Adidas Slides",
        price: "85",
        inventory: "16",
        category: "footwear",
        status: "published"
    },
    {
        name: "Ahenema Sandals",
        price: "45",
        inventory: "5",
        category: "footwear",
        status: "published"
    },
    {
        name: "Ahenema Sandals (HQ)",
        price: "185",
        inventory: "3",
        category: "footwear",
        status: "published"
    },
    {
        name: "Cowpea Chain",
        price: "350",
        inventory: "11",
        category: "necklace",
        status: "pending"
    },
    {
        name: "Cowpea Bracelet",
        price: "175",
        inventory: "11",
        category: "bracelet",
        status: "pending"
    },
]

module.exports = ITEMS;