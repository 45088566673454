// third-party components and hooks
import { useState } from "react";

// images
import ICON_X from "../images/x.svg"
import WGF24 from "../images/wear_ghana_festival_24_logo.png"

// components
import { LAYOUT_DEFAULT } from "../components/layouts";
import { Button, Divider, FormContainer, InputField } from "../components/form_elements";

// styles
import "../styles/page_profile.css"

export default function PageProfile () {
    // state holders
    const [ edit_window, set_edit_window ] = useState(false)
    const form_data_change_password = useState()
    const form_data_delete_account = useState()
    const form_data_personal_information = useState()
    const form_data_social_media_information = useState()

    // functions/ lambda
    const handle_change_password_submit = () => {}

    const handle_delete_account_submit = () => {}

    const handle_update_personal_data_submit = () => {}

    const handle_update_social_data_submit = () => {}

    const toggleEditWindow = (section) => {
        if (edit_window === "" || edit_window === section) {
            set_edit_window(false)
        } else { set_edit_window(section) }
    }

    return (
        <LAYOUT_DEFAULT pageName={ `profile` }>
            <main>
                <section>
                    <div className="head">
                        <div className="title">Account Information</div>
                    </div>
                    <div className="content">
                        <div id="user">
                            <div className="profile-picture"><img src={ WGF24 } alt="profile" /></div>
                            <div className="text-and-ctas">
                                <div className="name">Vendor</div>
                                <div className="id">Vendor ID: 123456</div>
                                <div className="ctas">
                                    <div onClick={ () => toggleEditWindow(`change_password`) }>Change Password</div>
                                    <div onClick={ () => toggleEditWindow(`delete_account`) }>Delete Account</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="head">
                        <div className="title">Personal Information</div>
                        <div className="cta" onClick={ () => toggleEditWindow(`personal_information`) }>Edit</div>
                    </div>
                    <div className="content">
                        <Field label="Name" value="Some Owner" />
                        <Field label="Email" value="some@email.com" />
                        <Field label="Telephone" value="+1234567890" />
                    </div>
                </section>

                <section>
                    <div className="head">
                        <div className="title">Social Media Information</div>
                        <div className="cta" onClick={ () => toggleEditWindow(`social_media_information`) }>Edit</div>
                    </div>
                    <div className="content">
                        <Field label="Facebook" value="Some Owner" />
                        <Field label="Twitter" value="some@email.com" />
                        <Field label="Intergram" value="+1234567890" />
                        <Field label="Pinterest" value="+1234567890" />
                    </div>
                </section>
            </main>

            {
                // if edit_window is not false
                edit_window ?
                <aside>
                    <div className="close" onClick={ () => toggleEditWindow("") }><img src={ ICON_X } alt="close" /></div>
                    {/* if edit_window = personal_information */}
                    {   edit_window === `change_password` ?
                        <FormContainer context={ form_data_change_password }>
                            <div>Changing Password</div>
                            <Divider />
                            <InputField label={ `Password` } type={ `password` } required={ true } name={ `input_password` } id={ `input_password` } />
                            <Divider />
                            <InputField label={ `New Password` } type={ `password` } required={ true } name={ `input_new_password_take_1` } id={ `input_new_password_take_1` } />
                            <InputField label={ `Confirm Password` } type={ `password` } required={ true } name={ `input_new_password_take_2` } id={ `input_new_password_take_2` } />
                            <Divider />
                            <Button callback={ handle_change_password_submit } label={ `Submit` } />
                        </FormContainer> : null }
                    {/* else if edit_window = social_media_information */}
                    {   edit_window === `delete_account` ?
                        <FormContainer context={ form_data_delete_account }>
                            <div>Deleting Account</div>
                            <Divider />
                            <InputField label={ `Password` } type={ `password` } required={ true } name={ `input_password` } id={ `input_password` } />
                            <Divider />
                            <Button callback={ handle_delete_account_submit } label={ `Submit` } />
                        </FormContainer> : null }
                    {/* if edit_window = personal_information */}
                    {   edit_window === `personal_information` ?
                        <FormContainer context={ form_data_personal_information }>
                            <div>Editing Personal Information</div>
                            <Divider />
                            <InputField label={ `Password` } type={ `password` } required={ true } name={ `input_password` } id={ `input_password` } />
                            <Divider />
                            <InputField label={ `Name` } type={ `text` } required={ true } name={ `input_name` } id={ `input_name` } />
                            <InputField label={ `Email` } type={ `email` } required={ true } name={ `input_email` } id={ `input_email` } />
                            <InputField label={ `Telephone` } type={ `tel` } required={ true } name={ `input_telephone` } id={ `input_telephone` } />
                            <Divider />
                            <Button callback={ handle_update_personal_data_submit } label={ `Submit` } />
                        </FormContainer> : null }
                    {/* else if edit_window = social_media_information */}
                    {   edit_window === `social_media_information` ?
                        <FormContainer context={ form_data_social_media_information }>
                            <div>Editing Social Media Information</div>
                            <Divider />
                            <InputField label={ `Password` } type={ `password` } required={ true } name={ `input_password` } id={ `input_password` } />
                            <Divider />
                            <InputField label={ `Facebook` } type={ `url` } name={ `input_facebook` } id={ `input_facebook` } />
                            <InputField label={ `Twitter` } type={ `url` } name={ `input_twitter` } id={ `input_twitter` } />
                            <InputField label={ `Intagram` } type={ `url` } name={ `input_instagram` } id={ `input_instagram` } />
                            <InputField label={ `Pinterest` } type={ `url` } name={ `input_pinterest` } id={ `input_pinterest` } />
                            <Divider />
                            <Button callback={ handle_update_social_data_submit } label={ `Submit` } />
                        </FormContainer> : null }
                </aside> :
                null
            }
        </LAYOUT_DEFAULT>
    )
}

function Field ({ label, value }) {
    return (
        <div className="field">
            <div className="label">{ label }</div>
            <div className="value">{ value }</div>
        </div>
    )
}