// third-party components and hooks
import { useState } from "react";

// components
import { LAYOUT_DEFAULT } from "../components/layouts";
import { Button, Divider, FormContainer, InputField } from "../components/form_elements";

// images
import ICON_X from "../images/x.svg"
import IMG_MASTERCARD_LOGO from "../images/mastercard_logo.png"
import IMG_MTN_MOBILE_MONEY_LOGO from "../images/mtn_mobile_money_logo.png"
import IMG_VISA_LOGO from "../images/visa_logo.png"
import IMG_VODAFONE_CASH_LOGO from "../images/vodafone_cash_logo.png"

// styles
import "../styles/page_payments.css"

export default function PagePayments () {
    // state holders
    // const [ edit_window_is_open, set_edit_window_is_open ] = useState(false)
    // const [ edit_window_content, set_edit_window_content ] = useState(``)
    const [ edit_window, set_edit_window ] = useState(false)
    // const [ form_data_add_payment, set_form_data_add_payment ] = useState({})
    const form_data_add_payment = useState()
    const form_data_change_default_payment = useState()

    // functions/ lambda
    const handle_default_payment_submit = () => {}

    const handle_new_payment_submit = () => {}

    const toggleEditWindow = (section, param) => {
        if (edit_window === "" || edit_window === section) { set_edit_window(false) }
        else {
            set_edit_window(section)
            // if (section === `add_payment`) { set_form_data_add_payment(param) }
        }
    }

    return (
        <LAYOUT_DEFAULT pageName={ `payments` }>
            <main>
                <section>
                    <div className="head">
                        <div className="title">Default</div>
                    </div>
                    <div className="content">
                        <div>Your preferred payment method</div>
                        <Field provider={ `visa` } name={ `**** **** **** ****`} expiry={ `10/24` } />
                    </div>
                    <div className="ctas">
                        <div onClick={ () => toggleEditWindow(`add_payment`) }>Add Payment Method</div>
                        <div onClick={ () => toggleEditWindow(`change_default_payment`) }>Change Default Payment Method</div>
                    </div>
                </section>

                <section>
                    <div className="head">
                        <div className="title">Cards</div>
                        <div className="cta" onClick={ () => toggleEditWindow(`add_payment`, { type: `card` }) }>Add New Card</div>
                    </div>
                    <div className="content">
                        <Field provider={ `mastercard` } name={ `**** **** **** ****`} expiry={ `10/24` } />
                        <Field provider={ `mastercard` } name={ `**** **** **** ****`} expiry={ `10/24` } />
                        <Field provider={ `visa` } name={ `**** **** **** ****`} expiry={ `10/24` } />
                    </div>
                </section>

                <section>
                    <div className="head">
                        <div className="title">Mobile Wallets</div>
                        <div className="cta" onClick={ () => toggleEditWindow(`add_payment`, { type: `wallet` }) }>Add New Number</div>
                    </div>
                    <div className="content">
                        <Field provider={ `vodafone` } name={ `*** *** **78`} />
                        <Field provider={ `mtn` } name={ `*** *** **12`} />
                    </div>
                </section>
            </main>

            {
                // if edit_window is not false
                edit_window ?
                <aside>
                    <div className="close" onClick={ () => toggleEditWindow("") }><img src={ ICON_X } alt="close" /></div>
                    {/* if edit_window = personal_information */}
                    {   edit_window === `add_payment` ?
                        <FormContainer context={ form_data_add_payment }>
                            <div>New Payment</div>
                            <Divider />
                            <InputField label={ `Password` } type={ `password` } required={ true } name={ `input_password` } id={ `input_password` } />
                            <Divider />
                            <InputField label={ `Type` } type={ `radio` } required={ true } name={ `input_type` } id={ `input_type` } options={ [ `Card`, `Wallet` ] } /> *
                            <InputField label={ `Number` } type={ `text` } required={ true } name={ `input_number` } id={ `input_number` } />
                            {
                                form_data_add_payment?.type === `card` ?
                                <InputField label={ `Expiry` } type={ `number` } required={ true } name={ `input_expiry` } id={ `input_expiry` } />
                                : null
                            }
                            <Divider />
                            <Button callback={ handle_new_payment_submit } label={ `Submit` } />
                        </FormContainer> : null }
                    {/* else if edit_window = social_media_information */}
                    {   edit_window === `change_default_payment` ?
                        <FormContainer context={ form_data_change_default_payment }>
                            <div>Changing Default Payment</div>
                            <Divider />
                            <InputField label={ `Password` } type={ `password` } required={ true } name={ `input_password` } id={ `input_password` } />
                            <Divider />
                            <InputField label={ `Payment Options` } type={ `radio` } required={ true } name={ `input_default` } id={ `input_default` } options={ [] } />
                            <Button callback={ handle_default_payment_submit } label={ `Submit` } />
                        </FormContainer> : null }
                </aside> : null
            }
        </LAYOUT_DEFAULT>
    )
}

function Field ({ provider, name, expiry }) {
    return (
        <div className="field">
            {/* <div className="provider">{ provider }</div> */}
            <div className="provider">
                <img src={
                    provider === "vodafone" ? IMG_VODAFONE_CASH_LOGO : 
                    provider === "mtn" ? IMG_MTN_MOBILE_MONEY_LOGO : provider === "visa" ? IMG_VISA_LOGO :
                    provider === "mastercard" ? IMG_MASTERCARD_LOGO : null
                } alt="" /></div>
            <div className="name">{ name }</div>
            { expiry ? <div className="expiry">{ expiry }</div> : null }
        </div>
    )
}