// third-party components
import { useEffect, useState } from "react";
import { Link } from "react-router-dom"

// images
import WGF24 from "../images/wear_ghana_festival_24_logo.png"
import ICON_PLUS from "../images/plus.svg"

// components
import { CARD_ITEM } from "../components/cards";
import { LAYOUT_DEFAULT } from "../components/layouts";
import { NAV_TABS } from "../components/navs";

// test data
import ITEMS from "../test_data/items"

// styles
import "../styles/page_items.css"

export default function PageItems () {
    // state holders
    const [ itemsFilter, setItemsFilter ] = useState(`all`)
    const [ filteredItems, setFilteredItems ] = useState()
    // const sorting_order = useState(`Default`);

    // state watchers
    useEffect(() => {
        var result = ITEMS;

        if (itemsFilter !== `all`) { result = ITEMS.filter((item) => item[itemsFilter.split(":")[0]] === itemsFilter.split(":")[1]) }

        setFilteredItems(result)
    }, [itemsFilter])

    // functions
    // const sortItems = (order) => {}

    return (
        <LAYOUT_DEFAULT pageName="items">
            <main>
                <div id="intro">
                    <div className="title">Top Sellng Product</div>
                    <div className="summary">
                        <div className="image"><img src={ WGF24 } alt="top selling product" /></div>
                        <div className="text">
                            <div>27 Sold</div>
                            <div>33 Left</div>
                            <div>$ 14.50 (Average Price)</div>
                        </div>
                    </div>
                </div>

                <div id="head">
                    <NAV_TABS isDynamic={ true }>
                        {/* <div id="sorting">
                            <div>Sort by</div>
                            <div className="options">
                                <div className="active"><div onClick={ () => sortItems(`Default`) }>Default</div></div>
                                <div className="alts">
                                    <div onClick={ () => sortItems(`Inventory: L-H`) }>Inventory: L-H</div>
                                    <div onClick={ () => sortItems(`Inventory: H-L`) }>Inventory: H-L</div>
                                    <div onClick={ () => sortItems(`Price: L-H`) }>Price: L-H</div>
                                    <div onClick={ () => sortItems(`Price: H-L`) }>Price: H-L</div>
                                </div>
                            </div>
                        </div> */}
                        <div className={ itemsFilter === `all` ? `active` : null } onClick={ () => setItemsFilter(`all`) }>All</div>
                        <div className={ itemsFilter === `status:pending` ? `active` : null } onClick={ () => setItemsFilter(`status:pending`) }>Pending/ Under Review</div>
                        <div className={ itemsFilter === `status:published` ? `active` : null } onClick={ () => setItemsFilter(`status:published`) }>Published</div>
                        <div className={ itemsFilter === `category:fabric` ? `active` : null } onClick={ () => setItemsFilter(`category:fabric`) }>Cloths and Fabrics</div>
                        <div className={ itemsFilter === `category:top` ? `active` : null } onClick={ () => setItemsFilter(`category:top`) }>Tops</div>
                        <div className={ itemsFilter === `category:bottom` ? `active` : null } onClick={ () => setItemsFilter(`category:bottom`) }>Bottoms</div>
                        <div className={ itemsFilter === `category:footwear` ? `active` : null } onClick={ () => setItemsFilter(`category:footwear`) }>Footwear</div>
                        <div className={ itemsFilter === `category:bracelet` ? `active` : null } onClick={ () => setItemsFilter(`category:bracelet`) }>Beads and Bracelets</div>
                        <div className={ itemsFilter === `category:necklace` ? `active` : null } onClick={ () => setItemsFilter(`category:necklace`) }>Chains</div>
                        <div className={ itemsFilter === `category:hat` ? `active` : null } onClick={ () => setItemsFilter(`category:hat`) }>Hats</div>
                    </NAV_TABS>
                </div>
                
                <div id="content">
                    {
                        filteredItems?.map((item, count) => {
                            return (
                                <Link to={ `/item_details` } key={ count }>
                                    <CARD_ITEM style={ `grid` } image={ WGF24 } name={ item?.name } price={ `$ ${item?.price}` } inventory={ `${ item?.inventory } Left` } />
                                </Link>
                            )
                        })
                    }
                </div>

                <Link to={ `/add_item` } id="add-new">
                    <div><img src={ ICON_PLUS } alt="" /></div>
                    <div>Add New Item</div>
                </Link>
            </main>
        </LAYOUT_DEFAULT>
    )
}