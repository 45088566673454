// components
import { NAV_BAR } from "./navs"

// styles
import "../styles/layouts.css"

// for auth related pages (sign up, sign in, reset password)
export function LAYOUT_AUTH ({ children, pageName }) {
    return (
        <div id="page" className="layout auth" data-page-name={ pageName }>
            { children }
        </div>
    )
} 

// for all other pages not auth related
export function LAYOUT_DEFAULT (props) {
    return (
        <div id="page" className="layout default" data-page-name={ props?.pageName }>
            <NAV_BAR links={ [
                {
                    name: "Profile",
                    pathname: "/profile"
                },
                {
                    name: "Payments",
                    pathname: "/payments"
                }
            ] } />
            <div id="content">
                {
                    props?.children
                }
            </div>
        </div>
    )
}