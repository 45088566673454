// third-party components
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom"

// images
import ARROW_LEFT from "../images/arrow_left.svg"
import WGF24 from "../images/wear_ghana_festival_24_logo.png"
import PP from "../images/wear_ghana_festival_24_logo.png"

// styles
import "../styles/navs.css"

export function NAV_AUTH_PAGE () {
    return (
        <div className="nav auth">
            <div className="brand"><img src={ WGF24 } alt="brand" /></div>
        </div>
    )
}

export function NAV_BAR (props) {
    // hook and state holders
    const location = useLocation();
    const navigate = useNavigate();

    // local lambda
    const toggleAccountManagerOptions = () => { document.querySelector(".account-manager").classList.toggle("drop") }
    const toggleNav = () => document.querySelector(".nav.bar").classList.toggle("closed")

    return (
        <div className="nav bar closed">
            <div className="head">
                {
                    location?.pathname === "/dashboard" ?
                    <div className="brand"><img src={ WGF24 } alt="brand" /></div> :
                    <div className="back" onClick={ () => navigate(-1) }><img src={ ARROW_LEFT } alt="back" /></div>
                }
                <div className="menu" onClick={ toggleNav }>MENU</div>
            </div>

            <div className="body">
                {
                    props?.links ?
                    <div className="links">
                        { location?.pathname !== "/dashboard" ? <NavLink to={ "/dashboard" }>Dashboard</NavLink> : null }
                        { location?.pathname !== "/items" ? <NavLink to={ "/items" }>Items</NavLink> : null }
                        {
                            props?.links?.map((link) => {
                                return (
                                    <NavLink key={ link?.pathname } to={ link?.pathname } isactive="active">{ link?.name }</NavLink>
                                )
                            })
                        }
                    </div> : null
                }

                <div className="account-manager">
                    <div className="controller" onClick={ toggleAccountManagerOptions }>
                        <div className="name">John Doe</div>
                        <div className="picture"><img src={ PP } alt="" /></div>
                    </div>

                    <div className="options">
                        <Link to={ `/sessions` }>Sessions</Link>
                        <Link to={ `/` }>Sign Out</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function NAV_TABS (props) {
    return (
        <div className={ `nav tabs ${ props?.isDynamic ? "dynamic" : "" }` }
        >{ props?.children }</div>
    )
}