// third-party components
import { useState } from "react";
import { Link } from "react-router-dom";

// images
import WGF24 from "../images/wear_ghana_festival_24_logo.png"
import ICON_PLUS from "../images/plus.svg"

// components
import { CARD_ITEM } from "../components/cards";
import { LAYOUT_DEFAULT } from "../components/layouts";

// test data
import ITEMS from "../test_data/items"

// styles
import "../styles/page_dashboard.css"
import { useEffect } from "react";

export default function PageDashboard () {
    // state holders
    const [ itemsFilter, setItemsFilter ] = useState(`all`)
    const [ filteredItems, setFilteredItems ] = useState()

    // state watchers
    useEffect(() => {
        var result = ITEMS;

        if (itemsFilter === `pending`) { result = ITEMS.filter((item) => item.status === `pending`) }
        if (itemsFilter === `published`) { result = ITEMS.filter(item => item.status === `published`) }

        setFilteredItems(result)
    }, [itemsFilter])

    return (
        <LAYOUT_DEFAULT pageName="dashboard">
            {/* left side */}
            <main>
                <div className="head">
                    <h3 className="title">OVERVIEW</h3>
                </div>
                <div className="content">
                    <div>Monthly sales performance</div>
                    <div>Monthly performance by item</div>
                    <div>Inventory per item</div>
                </div>
            </main>

            {/* right side */}
            <aside>
                <div className="head">
                    <h3 className="title">ITEMS</h3>
                    <div className="ctas">
                        <Link to="/add_item" className="cta"><img src={ ICON_PLUS } alt="Add New Item" /></Link>
                        <Link to="/items" className="cta">View All</Link>
                    </div>
                </div>

                <div className="content">
                    {
                        filteredItems?.map((item, count) => {
                            return (
                                count < 6 ?
                                <Link to={ `/item_details` } key={ count }>
                                    <CARD_ITEM style={ `list` } image={ WGF24 } name={ item?.name } price={ `$ ${item?.price}` } inventory={ `${ item?.inventory } Left` } />
                                </Link> : null
                                    
                            )
                        })
                    }
                </div>

                <div className="other">
                    <div title="View all item(s)" onClick={ () => setItemsFilter(`all`) }>{ ITEMS.length } Total</div>
                    <div title="View only published item(s)" onClick={ () => setItemsFilter(`published`) }>{ ITEMS.filter(({ status }) => status === `published`).length } Published</div>
                    <div title="View only pending item(s) under review" onClick={ () => setItemsFilter(`pending`) }>{ ITEMS.filter(({ status }) => status === `pending`).length } Pending</div>
                </div>
            </aside>
        </LAYOUT_DEFAULT>
    )
}